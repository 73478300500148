




import Vue from 'vue'
import ErrorMessage from '@/components/common/ErrorMessage.vue'

export default Vue.extend({
  name: 'NotFoundPage',
  components: { ErrorMessage },
  auth: false,
})
